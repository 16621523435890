/* global axios */
// import ApiClient from './ApiClient';
import CacheEnabledApiClient from './CacheEnabledApiClient';

class CustomActionsAPI extends CacheEnabledApiClient {
  constructor() {
    super('custom_actions', { accountScoped: true });
  }

  get cacheModelName() {
    return 'customAction';
  }

  executeCustomAction({ customActionId }) {
    return axios.get(`${this.url}/${customActionId}/execute`, {});
  }

  execute(customActionId, method = 'get') {
    const supportedMethods = ['get', 'post', 'put', 'patch', 'delete'];
    method = method.toLowerCase();

    if (!supportedMethods.includes(method)) {
      throw new Error(`Unsupported HTTP method: ${method}`);
    }

    return axios[method](`${this.url}/${customActionId}/execute`);
  }

  updateStatus(customActionId, status) {
    return this.update(customActionId, { active: status });
  }

  getLogs(customActionId) {
    return axios.get(`${this.url}/${customActionId}/logs`);
  }

  updateMappings(customActionId, mappings) {
    return axios.patch(`${this.url}/${customActionId}/mappings`, { mappings });
  }
}

export default new CustomActionsAPI();